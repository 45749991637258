<script setup lang="ts">
import type { Badge } from "@/types";

const emit = defineEmits(["onTimerEnd"]);

const props = defineProps<{
	imgDesk: string;
	imgMob: string;
	bgDesk: string;
	bgMob: string;
	title?: string;
	smallTitle?: string;
	subTitle?: string;
	buttonText?: string;
	buttonLink?: string;
	coins?: number;
	entries?: number;
	usualPrice?: number;
	time?: number | string;
	text?: string;
	badgeLabel?: string;
	bestDeal?: boolean;
	mostPopular?: boolean;
	money?: number;
	type?: string;
}>();

const { t } = useT();
const isGuest = useIsGuest();
const prepareImgUrl = useImage();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { bonusesData } = useBonusesData({ immediate: false });

const promoOffer = computed(() => props?.coins || props?.entries);
const badgeLabel = (parseJSON(props?.badgeLabel || "{}") as Badge) || {};
const isTimerActive = computed(() => !promoOffer.value && props.time && !bonusesData.value?.dailyWheel?.available);
</script>

<template>
	<ABanner
		:img-attrs="{
			alt: 'banner',
			src: `${baseImageUrl}${props.imgDesk}`,
			format: 'avif',
			width: 340,
			height: 380,
			loading: 'lazy'
		}"
		:sources="[
			{
				srcset: prepareImgUrl(`${baseImageUrl}${props.imgMob}`, {
					format: 'avif',
					width: 330,
					height: 368,
					loading: 'lazy'
				}),
				width: 330,
				height: 368,
				format: 'avif',
				media: '(max-width: 767px)',
				loading: 'lazy'
			}
		]"
		:background="`url(${prepareImgUrl(`${baseImageUrl}${props.bgMob}`, {
			format: 'avif',
			loading: 'lazy',
			width: 602,
			height: 380
		})}) no-repeat center center / cover`"
		class="banner"
	>
		<div :class="['box', { 'is-timer': promoOffer && props.time }]">
			<MCounter
				v-if="promoOffer && props.time"
				:timestamp="props.time || ''"
				background="var(--primary-50)"
				class="promo-time"
				@on-timer-end="emit('onTimerEnd')"
			/>
			<MHomeBannerLabel v-else :badge="badgeLabel" :bestDeal="props.bestDeal" :mostPopular="props.mostPopular" />

			<ACornerBadge v-if="props.badgeLabel" variant="ribbon" modifiers="right" background-color="var(--gradient-g-3)">
				<AText :size="{ full: 18, lg: 12 }" class="text-neutral-0">
					{{ badgeLabel?.text }} {{ badgeLabel?.percent }}
				</AText>
			</ACornerBadge>
			<AText v-if="props.subTitle" :size="{ full: 24, lg: 14 }"> {{ props.subTitle }} </AText>
			<AText v-if="props.title" :size="{ full: 40, lg: 18 }" :modifiers="['bold', 'uppercase']" class="title">
				{{ props.title }}
			</AText>
			<AText v-if="props.smallTitle" :size="{ full: 24, lg: 14 }"> {{ props.smallTitle }} </AText>
			<div v-if="props.coins || props.entries" class="prize-blocks">
				<MPrizeFund v-if="props.coins" variant="coins" icon="20/coins" :icon-size="36">
					<AText class="text-neutral-100" :size="36">
						{{ numberFormat(props.coins) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund
					v-if="props.entries && !isGuest"
					class="margin-entries"
					variant="entries"
					icon="20/entries"
					:icon-size="36"
				>
					<AText class="text-neutral-100" :size="36">
						{{ numberFormat(props.entries) }}
						<AText :size="22" class="desk" :modifiers="['uppercase']"> {{ t("for free") }} </AText>
					</AText>
				</MPrizeFund>
			</div>
			<AText v-if="props.money && props.usualPrice" :size="{ full: 16, lg: 10 }" class="box-usual">
				<AText :size="{ full: 24, lg: 14 }" class="spec text-neutral-5" :modifiers="['bold']">
					${{ numberFormat(Number(props.money), { minimumFractionDigits: 2 }) }}
				</AText>
				{{ t("was") }}
				<del>${{ numberFormat(Number(props.usualPrice), { minimumFractionDigits: 2 }) }}</del>
			</AText>
			<div v-if="isTimerActive" class="box-counter">
				<ABadge variant="info" background="var(--primary-50)" autosize>
					<AText :size="10" class="text-neutral-100 mark" :modifiers="['nowrap']">
						{{ t("Next Spin In") }}
					</AText>
				</ABadge>
				<MCounter :timestamp="props.time || ''" isBadge @on-timer-end="emit('onTimerEnd')" />
			</div>
			<AButton v-if="props.buttonText && !isTimerActive" variant="primary" class="button">
				<AText :size="{ full: 16, lg: 12 }" :modifiers="['bold', 'uppercase']">
					{{ props.buttonText }}
				</AText>
			</AButton>
		</div>
	</ABanner>
</template>

<style scoped lang="scss">
.box {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 8px;
	padding: 16px 0 16px 32px;
	width: 100%;
	max-width: 382px;

	@include media-breakpoint-down(lg) {
		width: 215px;
		padding: 8px 16px;
		gap: 2px;

		&.is-timer {
			padding-top: 36px;
		}
	}
}

.banner {
	display: inline-flex;
	flex-shrink: 0;
	width: 674px;
	height: 380px;
	cursor: pointer;

	@media (max-width: 1300px) {
		width: 602px;
	}

	@include media-breakpoint-down(lg) {
		--a-banner-default-border-radius: 12px;
		--m-counter-width: 15px;
		--m-counter-height: 24px;
		--m-counter-font-size: 14px;

		width: 330px;
		height: 184px;

		&:deep(picture) {
			height: 100%;
			width: 165px;
			right: 0;
			transform: translate(0);

			img {
				width: 165px;
				height: 184px;
			}
		}
	}

	+ .banner:not([style*="translate3d"]) {
		margin-left: 16px;
	}
}

.button {
	margin-top: 8px;

	@include media-breakpoint-up(lg) {
		--a-button-default-padding: 16px;
	}
}

.promo-time {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 0 0 8px;

	@include media-breakpoint-down(lg) {
		padding: 4px 8px !important;
	}
}

.box-counter {
	margin-top: 8px;
	position: relative;

	.badge {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 16px;
		padding: 2px 8px;
	}
}

.box-usual {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.spec {
	border-radius: 8px;
	background: var(--tertiary-80);
	padding: 4px 8px;

	@include media-breakpoint-down(lg) {
		padding: 2px 4px;
	}
}

.prize-blocks {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		span,
		:deep(.icon) {
			font-size: 20px;
		}
	}
}

.desk {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>
